import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { announceError, announceSuccess } from './app.actions';

@Injectable({ providedIn: 'root' })
export class AppEffects {
  announceError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(announceError),
        switchMap(error => {
          let message: string | undefined;

          if (typeof error.error === 'string') {
            message = error.error;
          } else if (typeof error.error === 'object') {
            if (error.error instanceof HttpErrorResponse) message = error.error.statusText;
            else if (error.error instanceof Error) message = error.error.message;

            // Ignore error responses that Django returns as a webpage, display boilerplate message
            if (message?.includes('<html'))
              message = this.transloco.translate('something_went_wrong_try_again_later');
          }

          return of(
            this.snackbar.open(
              message ?? this.transloco.translate('something_went_wrong_try_again_later'),
              this.transloco.translate('dismiss'),
              { duration: 5_000, horizontalPosition: 'center', verticalPosition: 'bottom' }
            )
          );
        })
      ),
    { dispatch: false }
  );

  announceSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(announceSuccess),
        switchMap(success => {
          return of(
            this.snackbar.open(
              success.translate ? this.transloco.translate(success.message) : success.message,
              this.transloco.translate('dismiss'),
              { duration: 5_000, horizontalPosition: 'center', verticalPosition: 'bottom' }
            )
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly snackbar: MatSnackBar,
    private readonly transloco: TranslocoService
  ) {}
}
