import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { getRouterOutletState } from '../shared/helpers/utility';

@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.scss'],
  // animations: [routerTransition],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsightsComponent implements OnInit {
  ngOnInit(): void {}

  getState = getRouterOutletState;
}
