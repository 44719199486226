import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Class wrapping information coming over the network. Indicates whether the information
 * is still being received, and in the case that the information has been received, what
 * the information was.
 *
 * @author Jacob
 */
export class Loadable<T> {
  /**
   * Determines whether currently loading
   */
  isLoading = false;

  /**
   * Determines whether currently in an error state
   */
  error?: string;

  /**
   * Value  of Loadable<T>, or null if currently loading
   */
  _value?: T | null;

  /**
   * Gets the value currently contained by this Loadable<T>
   */
  public get value() {
    return this.isLoading || !!this.error ? undefined : this._value;
  }

  /** Loadable is untouched if it is not loading and its value is still in its undefined state */
  get untouched(): boolean {
    return !this.isLoading && !this.error && this._value === undefined;
  }

  /**
   * Creates a new Loadable<T>
   * @param value the initial value for this Loadable<T>
   * @param loading whether this Loadable<T> is loading
   * @param error whether this Loadable<T> is currently in an error state
   */
  constructor(
    value: T | null | undefined = undefined,
    loading = false,
    error: string | undefined = undefined
  ) {
    this._value = value;
    this.isLoading = loading;
    this.error = error;
  }

  /**
   * @param loading whether loading
   * @returns a copy of this Loadable<T> with the same value and error status
   */
  loadingCopy(loading: boolean) {
    return new Loadable(structuredClone(this.value), loading, this.error);
  }

  /**
   * @param value the potential new value
   * @returns a copy of this Loadable<T> with the same loading status
   * and error status, but a potentially different value
   */
  valueCopy(value?: T) {
    return new Loadable(structuredClone(value), this.isLoading);
  }

  /**
   *
   * @param error the error status
   * @returns a copy of this Loadable<T> with a different
   * error status
   */
  errorCopy(error: string | HttpErrorResponse | undefined) {
    let message: string | undefined;

    if (typeof error === 'string') {
      message = error;
    } else if (typeof error === 'object') {
      if (error instanceof HttpErrorResponse) message = error.statusText;
      if (error instanceof Error) message = error.message;
    }

    return new Loadable(structuredClone(this.value), false, message);
  }

  /**
   * @returns an identical copy of this Loadable<T>
   */
  copy() {
    return new Loadable(structuredClone(this.value), this.isLoading, this.error);
  }
}

/** Returns a copy of a type with all attributes nullable and added "error" string attribute */
export type MayError<T> = Partial<T> & { error?: string };

/**
 * RxJS pipe operator executes a callback function on a Loadable observable if it is `untouched`.
 *
 * Callback gets observable's value, but similarly to the `tap` operator does not modify it
 * when passing it down the pipe
 */
export function onUntouched<T>(callback: (value: T) => void) {
  return (observable: Observable<T>) =>
    new Observable<T>(subscriber => {
      const subscription = observable.subscribe({
        next(value) {
          if (!(value instanceof Loadable)) {
            console.warn('Observable is not `Loadable`. Cannot execute your function');
            subscriber.complete();
            return;
          }
          if (value.untouched) callback(value);
          subscriber.next(value);
        },
        error(err) {
          subscriber.error(err);
        },
        complete() {
          subscriber.complete();
        },
      });

      return () => subscription.unsubscribe();
    });
}
