import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FooterModule } from '../core/footer/footer.module';
import { MonitoringRoutingModule } from './monitoring-routing.module';
import { MonitoringComponent } from './monitoring.component';
import { GeofencesStoreService } from './pages/geofences/store/geofences-store.service';
import { LiveDataService } from './pages/live/live-data.service';
import { LiveStoreService } from './pages/live/store/live-store.service';
import { LiveEffects } from './pages/live/store/live.effects';
import { liveReducer } from './pages/live/store/live.reducers';
import { ReplayEffects } from './pages/replay/store/replay.effects';
import { replayReducer } from './pages/replay/store/replay.reducer';
import { MonitoringGeneralEffects } from './store/general/monitoring.effects';
import { monitoringGeneralReducer } from './store/general/monitoring.reducer';

@NgModule({
  declarations: [MonitoringComponent],
  imports: [
    CommonModule,
    MonitoringRoutingModule,
    StoreModule.forFeature('monitoring', [monitoringGeneralReducer, liveReducer, replayReducer]),
    EffectsModule.forFeature([MonitoringGeneralEffects, LiveEffects, ReplayEffects]),
    FooterModule,
  ],
  providers: [LiveStoreService, LiveDataService, GeofencesStoreService],
})
export class MonitoringModule {}
