import { createAction, props } from '@ngrx/store';
import { MayError } from '../../shared/loading-state/loadable';
import {
  Reminder,
  ReminderCategory,
  ReminderCategoryDTO,
  ReminderDTO,
} from '../maintenance.models';

/* --------------- Reminder CRUD --------------- */

export const createReminder = createAction(
  '[Maintenance] Create Reminder',
  props<{ reminder: ReminderDTO }>()
);
export const updateReminder = createAction(
  '[Maintenance] Update Reminder',
  props<{ reminder: Reminder }>()
);
export const deleteReminder = createAction(
  '[Maintenance] Delete Reminder',
  props<{ id: number }>()
);

export const getReminders = createAction('[Maintenance] Get Reminders');
export const setReminders = createAction(
  '[Maintenance] Reminders',
  props<MayError<{ reminders: Reminder[] }>>()
);

/* --------------- ReminderTrigger CRUD --------------- */

export const getReminderVehicles = createAction(
  '[Maintenance] Get Reminder Vehicles',
  props<{ reminder: number }>()
);

/* --------------- ReminderCategory CRUD --------------- */

export const createReminderCategory = createAction(
  '[Maintenance] Create Reminder Category',
  props<{ category: ReminderCategoryDTO }>()
);
export const updateReminderCategory = createAction(
  '[Maintenance] Update Reminder Category',
  props<{ category: ReminderCategory }>()
);
export const deleteReminderCategory = createAction(
  '[Maintenance] Delete Reminder Category',
  props<{ id: number }>()
);

export const getReminderCategories = createAction('[Maintenance] Get Reminder Categories');
export const setReminderCategories = createAction(
  '[Maintenance] Set Reminder Categories',
  props<MayError<{ categories: ReminderCategory[] }>>()
);
