import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { VehicleAnalyticsEffects } from '../analytics/pages/vehicle/store/vehicle-analytics.effects';
import { FooterModule } from '../core/footer/footer.module';
import { AdsModule } from '../shared/ads/ads.module';
import { InsightsRoutingModule } from './insights-routing.module';
import { InsightsComponent } from './insights.component';
import { InsightsGeneralEffects } from './store/general/insights.effects';
import { insightsGeneralReducer } from './store/general/insights.reducer';

@NgModule({
  declarations: [InsightsComponent],
  imports: [
    CommonModule,
    InsightsRoutingModule,
    StoreModule.forFeature('insights', [insightsGeneralReducer]),
    EffectsModule.forFeature([InsightsGeneralEffects, VehicleAnalyticsEffects]),
    AdsModule,
    FooterModule,
  ],
})
export class InsightsModule {}
