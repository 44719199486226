import { Action, createReducer, on } from '@ngrx/store';
import { Loadable } from '../../shared/loading-state/loadable';
import { resetState } from '../../store/app.actions';
import { Reminder, ReminderCategory } from '../maintenance.models';
import * as MaintenanceActions from './maintenance.actions';

export type MaintenanceState = {
  reminders: Loadable<Reminder[]>;
  reminderCategories: Loadable<ReminderCategory[]>;
};

const initialState: MaintenanceState = {
  reminders: new Loadable(),
  reminderCategories: new Loadable(),
};

const reducer = createReducer<MaintenanceState>(
  initialState,
  on(resetState, () => ({
    ...initialState,
  })),
  // Reminders
  on(MaintenanceActions.getReminders, state => ({
    ...state,
    reminders: state.reminders.loadingCopy(true),
  })),
  on(MaintenanceActions.setReminders, (state, { reminders, error }) => ({
    ...state,
    reminders: !error ? new Loadable(reminders) : state.reminders.errorCopy(error),
  })),
  // Reminder Categories
  on(MaintenanceActions.getReminderCategories, state => ({
    ...state,
    reminderCategories: state.reminderCategories.loadingCopy(true),
  })),
  on(MaintenanceActions.setReminderCategories, (state, { categories, error }) => ({
    ...state,
    reminderCategories: !error
      ? new Loadable(categories)
      : state.reminderCategories.errorCopy(error),
  }))
);

export function maintenanceReducer(
  state: MaintenanceState | undefined,
  action: Action
): MaintenanceState {
  return reducer(state, action);
}
export const maintenanceFeatureKey = 'maintenance';
