import { trigger } from '@angular/animations';
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import {
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Output,
  Self,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ALL_TRANSITION_ANIMATIONS } from './overlay.transitions';

@Component({
  selector: 'shared-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  animations: [trigger('fade', ALL_TRANSITION_ANIMATIONS)],
})
export class PopoverComponent {
  @ViewChild('overlay', { read: CdkConnectedOverlay })
  overlay!: CdkConnectedOverlay;

  @Input()
  anchor!: CdkOverlayOrigin;

  @Input('class')
  panelClass: string | string[] = [];

  /**
   * Applies a css class to the backdrop element spanning the page behind the oevrlay
   *
   * Use `class="cdk-overlay-dark-backdrop"` to apply default dark translucent backdrop styling
   */
  @Input()
  backdropClass: string | string[] = [];

  @Input('open')
  isOpen = false;
  @Output()
  openChange = new EventEmitter<boolean>();

  /**
   * Positioning of the popover on the page.
   *
   * `relative` - popover will be positioned in a space relative to its anchor \
   * `absolute` - popover will be positioned relative to the body element
   *              regardless of its original anchor position
   */
  @Input()
  position: 'relative' | 'absolute' = 'relative';

  /**
   * If you have supplied a `popoverContext` to an associated `[popoverTrigger]`
   * this member will reflect any data passed through `popoverContext`.
   *
   * Context is flushed every time popover is closed.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context: any = {};

  toggle(): void {
    this.isOpen = !this.isOpen;
    this.openChange.emit(this.isOpen);
  }

  open(): void {
    this.isOpen = true;
    this.openChange.emit(this.isOpen);
  }

  close(): void {
    this.isOpen = false;
    this.context = {};
    this.openChange.emit(this.isOpen);
  }

  onBackdropClick(): void {
    this.close();
  }

  ngOnDestroy(): void {
    this.openChange.complete();
  }
}

@Directive({
  selector: '[popoverTriggerFor], [popoverTrigger]',
  exportAs: 'popoverTrigger',
})
export class PopoverTriggerDirective implements OnChanges, OnDestroy, CdkOverlayOrigin {
  @Input()
  popoverTriggerFor?: PopoverComponent;
  popoverToggle$?: Subscription;

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  popoverContext: any;

  @Output()
  readonly popoverOpen = new EventEmitter<void>();
  @Output()
  readonly popoverClosed = new EventEmitter<void>();

  constructor(
    @Self()
    @Optional()
    public readonly origin: CdkOverlayOrigin | null,
    public readonly elementRef: ElementRef
  ) {}

  @HostListener('click')
  private _onTriggerClick(): void {
    this.open();
  }

  toggle(open?: boolean): void {
    if (this.popoverTriggerFor && this.origin) {
      this.popoverTriggerFor.anchor = this.origin;
      this.popoverTriggerFor.context = this.popoverContext;
    }

    if (open) {
      this.popoverTriggerFor?.open();
      this.popoverOpen.emit();
    } else {
      this.popoverTriggerFor?.close();
      this.popoverClosed.emit();
    }
  }

  open(): void {
    this.toggle(true);
  }

  close(): void {
    this.toggle(false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['popoverTriggerFor'] && this.popoverTriggerFor !== undefined) {
      this.popoverToggle$?.unsubscribe();
      this.popoverToggle$ = this.popoverTriggerFor.openChange.subscribe(isOpen => {
        if (isOpen) this.popoverOpen.emit();
        else this.popoverClosed.emit();
      });

      if (!this.popoverTriggerFor.anchor && this.origin)
        this.popoverTriggerFor.anchor = this.origin;
    }
  }

  ngOnDestroy(): void {
    this.popoverToggle$?.unsubscribe();
    this.popoverOpen.complete();
    this.popoverClosed.complete();
  }
}
