import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

/**
 * When dispatched triggers all states to be reset to their initial states.
 *
 * ------------
 *
 * NOTE:
 * When creating a new ngrx/reducer make sure to add the following to the reducer:
 * ```
 * import { resetState } from '/store/app.actions'
 *
 * const initialState = {
 *   // your Reducer's initial state...
 * }
 *
 * const reducer = createReducer<MonitoringGeneralState>(
 *   initialState,
 *   on(resetState, () => ({
 *     ...initialState,
 *   })),
 *   // ...
 * )
 * ```
 *
 * ------------
 *
 * Auth's Reducer is made to ignore typical `resetState({})` call;
 * so, call `resetState({ resetAuth: true })` to force it to reset together with everything else
 */
export const resetState = createAction(
  '[App] Reset all States to Initial',
  props<{ resetAuth?: boolean }>()
);

/**
 * Flashes a standard app-wide Error MatSnackbar pop out to notify a user of a failed action.
 * Use with `catchError` operator inside ngrx/rxjs pipes or `store.dispatch` inside components' `ts`
 *
 * @example
 * ```
 * .dataService().pipe(
 *   map(response => ...),
 *   catchError(error => of(..., announceError({ error })))
 * )
 * ```
 */
export const announceError = createAction(
  '[App] Post Action Error',
  props<{ error: string | HttpErrorResponse | Error }>()
);

/**
 * Flashes a standard app-wide Success MatSnackbar pop oup to notify a user of a successful action.
 * Use with `switchMap` operator inside ngrx/rxjs pipes or `store.dispatch` inside components' `ts`
 *
 * Add `translate: true` to subject your `message` to transloco.translate treatment.
 * `message` does not get translated by default
 *
 * @example
 * ```
 * .dataService().pipe(
 *   map(response => [..., announceSuccess({ message: 'You are awesome!' })]),
 *   catchError(error => ...)
 * )
 * ```
 */
export const announceSuccess = createAction(
  '[App] Post Action Success',
  props<{ message: string; translate?: boolean }>()
);
