import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { adminAppReducers } from '../admin/store/admin.reducer';
import { analyticsAppReducers } from '../analytics/store/analytics.reducer';
import { RESET } from '../auth/store/auth.actions';
import { AuthState, authReducer } from '../auth/store/auth.reducer';
import { insightsAppReducers } from '../insights/store/insights.reducer';
import { MaintenanceState, maintenanceReducer } from '../maintenance/store/maintenance.reducer';
import { MonitoringAppState, monitoringAppReducers } from '../monitoring/store/monitoring.reducer';
import { DownloadState, downloadReducer } from '../shared/download/store/download.reducer';
import {
  SubscriptionState,
  subscriptionReducer,
} from '../shared/subscription/store/subscription.reducer';
import { VehicleState, vehicleReducer } from '../shared/vehicle/store/vehicle.reducer';

export type GlobalAppState = {
  auth: AuthState;
  vehicle: VehicleState;
  subscription: SubscriptionState;
  download: DownloadState;
  maintenance: MaintenanceState;
};
export type AppState = GlobalAppState & MonitoringAppState;

export const reducers: ActionReducerMap<AppState> = {
  auth: authReducer,
  ...monitoringAppReducers,
  ...analyticsAppReducers,
  ...insightsAppReducers,
  ...adminAppReducers,
  vehicle: vehicleReducer,
  subscription: subscriptionReducer,
  download: downloadReducer,
  maintenance: maintenanceReducer,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];

/**
 * This function will be executed for all dispatched actions
 * however it will only take action when the reset action is dispatched
 * upon logout and what it does is it clears the store state
 * back to it's initial state
 * @author Jason Yuan
 * TODO: This is not working as intended
 */
export function resetState(reducer: ActionReducer<AppState>) {
  return (state: AppState, action: Action): AppState => {
    if (action.type === RESET) state = {} as AppState;

    return reducer(state, action);
  };
}
